// @flow

import type { CategoryType } from '../flowTypes'
import { getLibrary } from '../services/dipseaApi'
import { cloneDeep } from 'lodash'
import type { TrackPropsType } from './Track'
import { convertTrackToProps } from './Track'
import { sortSlugs } from './helpers'

export async function getCategorySlugsAsync (): Promise<string[]> {
  const library = await getLibrary()
  return Object.keys(library?.slugs?.contexts)
}

export async function getCategoryBySlugAsync (slug: string): Promise<CategoryType> {
  const library = await getLibrary()
  const categoryId = library?.slugs?.contexts[slug]
  const category = cloneDeep(library?.contexts[categoryId] || {})
  return category
}

export async function getCategoryByIdAsync (id: string): Promise<CategoryType> {
  const library = await getLibrary()
  const category = cloneDeep(library?.contexts[id] || {})
  return category
}

export async function getAllCategoriesByTypeAsync (type: 'stories' | 'wellness' | 'sleep' | 'hunks' | 'creator'): Promise<CategoryType[]> {
  const library = await getLibrary()
  // $FlowFixMe
  const contextIds: string[] = Object.keys(library.contexts)
  return contextIds.filter((key: string): boolean => library.contexts?.[key]?.subType === type).sort((a: string, b: string): number => library.contexts[a].index - library.contexts[b].index).map((key: string): CategoryType => library.contexts[key])
}

export async function getSlugsByCategoryTypeAsync (type: 'stories' | 'wellness' | 'sleep' | 'hunks' | 'creator'): Promise<string[]> {
  const library = await getLibrary()
  const creators = await getAllCategoriesByTypeAsync(type)
  const slugs = []
  Object.keys(library?.slugs?.contexts).forEach((slug: string): void => {
    if (creators.find((creator: CategoryType): boolean => creator.id === library?.slugs?.contexts[slug])) {
      slugs.push(slug)
    }
  })
  return slugs
}

export function getEpisodeInformation (category: CategoryType, label: string): string {
  const episodeLabel = label || 'Episode'
  const totalStories = Object.keys(category.track_index || {}).length
  return `${totalStories} ${totalStories === 1 ? episodeLabel : episodeLabel + 's'}`
}

export const newReleasesCategory = '-LaxN3RylGGo5jXU4NTh'

export type NewReleasesPropsType = {
  title: 'New Releases',
  tracks: TrackPropsType[]
}
export async function getNewReleasesPropsAsync (): Promise<NewReleasesPropsType> {
  const library = await getLibrary()
  const trackIds = Object.keys(library.contexts[newReleasesCategory].track_index)
    .sort((a: string, b: string): number =>
      library.contexts[newReleasesCategory].track_index[a].index - library.contexts[newReleasesCategory].track_index[b].index
    )
  const tracks = trackIds
    .map((trackId: string): TrackPropsType => convertTrackToProps(library.tracks[trackId], library))
    .filter((track: TrackPropsType | null): boolean => !!track)
  return {
    title: 'New Releases',
    tracks
  }
}

export async function getCategorySlugAsync (id: string): Promise<string | null> {
  const library = await getLibrary()
  const category = library?.contexts[id]
  if (category.subType === 'hunks') {
    return '/characters/' + sortSlugs(category.slugs)[0]
  }
  return null
}
